import { RPMAvatar } from "@ravespaceio/rave-engine"
import { AvatarConfig_I } from "@ravespaceio/rave-engine/build/engineserver-common/src/SharedDataTypes/PlayerTypes"


export function getAvatarPreviewImg(modelUrl: string, callback: (prev: string) => void) {
	const params = {
		model: modelUrl,
		scene: "fullbody-posture-v1-transparent",
	}
	const http = new XMLHttpRequest()
	http.open("POST", "https://render.readyplayer.me/render")
	http.setRequestHeader("Content-type", "application/json")
	http.send(JSON.stringify(params))
	http.onload = () => {
		const response = JSON.parse(http.responseText)
		if (response.renders) {
			callback(response.renders[0])
		} else {
			callback("")
		}
	}
}

export function rpmRndScale(): number {
	return 1 + (Math.random() - 0.5) / 10
}


export type AvatarConfigWithoutPreview = {
	model: string,
	gender: "masculine" | "feminine"
	scale: number
}
export type AvatarConfig = AvatarConfigWithoutPreview & {
	preview: "loading" | string
}

export function transformAvatarConfig(config: AvatarConfigWithoutPreview): AvatarConfig_I {
	return {
		modelPath: config.model,
		animationsPath: config.gender == "masculine" ? RPMAvatar.defaultMaleConfig.animationsPath : RPMAvatar.defaultFemaleConfig.animationsPath,
		scale: rpmRndScale(),
		img: ""
	}
}


// https://readyplayer.me/
// export const RPM_API_CONFIG = ".glb?textureSizeLimit=1024&meshLod=2&textureAtlas=1024&useDracoMeshCompression=true&morphTargets=none"
export const RPM_API_CONFIG = ".glb?quality=medium"
export const RPM_API_URL = "https://api.readyplayer.me/v1/avatars/"
export const RPM_PRE_AVATARS: AvatarConfig[] = [
	{ model: "/dek/avatars/models/5.glb", gender: "feminine", preview: "/dek/avatars/img/5.png", scale: 1 },
	{ model: "/dek/avatars/models/1.glb", gender: "masculine", preview: "/dek/avatars/img/1.png", scale: 1 },
	{ model: "/dek/avatars/models/4.glb", gender: "masculine", preview: "/dek/avatars/img/4.png", scale: 1 },
	{ model: "/dek/avatars/models/6.glb", gender: "feminine", preview: "/dek/avatars/img/6.png", scale: 1 },
	{ model: "/dek/avatars/models/2.glb", gender: "masculine", preview: "/dek/avatars/img/2.png", scale: 1 },
	{ model: "/dek/avatars/models/3.glb", gender: "masculine", preview: "/dek/avatars/img/3.png", scale: 1 },
	{ model: "/dek/avatars/casual/f1.glb", gender: "feminine", preview: "/dek/avatars/casual/f1.png", scale: 1 },
	{ model: "/dek/avatars/casual/m1.glb", gender: "masculine", preview: "/dek/avatars/casual/m1.png", scale: 1 },
	{ model: "/dek/avatars/casual/f2.glb", gender: "feminine", preview: "/dek/avatars/casual/f2.png", scale: 1 },
	{ model: "/dek/avatars/casual/m2.glb", gender: "masculine", preview: "/dek/avatars/casual/m2.png", scale: 1 },
	{ model: "/dek/avatars/casual/f3.glb", gender: "feminine", preview: "/dek/avatars/casual/f3.png", scale: 1 },
	{ model: "/dek/avatars/casual/m3.glb", gender: "masculine", preview: "/dek/avatars/casual/m3.png", scale: 1 },
	{ model: "/dek/avatars/casual/f4.glb", gender: "feminine", preview: "/dek/avatars/casual/f4.png", scale: 1 },
	{ model: "/dek/avatars/casual/m4.glb", gender: "masculine", preview: "/dek/avatars/casual/m4.png", scale: 1 },
]



