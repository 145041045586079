import { getEngine } from "~/space/engine";
import { getSpace } from "~/space/space";
import { gsap, TweenMax, TweenLite, TimelineLite, Power3, Power2, Power1, Circ, Linear } from 'gsap'
import { HighlightCylinder, PlayerObject } from "@ravespaceio/rave-engine"
import { PlayerEntities } from "@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerObject"
import { SphereCollider } from "@ravespaceio/rave-engine/build/engine/src/helper/interaction/SimpleColliderSystem";
import { useSpaceStore } from "~/store/space";
import { dekaBotPositions, useGameStore } from "~/store/game";
import { resetDekaBot } from "../game/dekabot";
import * as THREE from "three"
import { PlayerCharacter } from '@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerCharacter/PlayerCharacter';




export default function setupMasterClass() {

	const space = getSpace()
	const engine = getEngine()
	const spaceStore = useSpaceStore()
	const gameStore = useGameStore()


	const hc = new HighlightCylinder()
	engine.scene.add(hc)
	hc.position.set(-13.19, 10.629, -3.56)

	space.pciSystem.add({
		collider: new SphereCollider(hc, 3),
		onColliderEnter: () => {
			spaceStore.hint.title = "Cinema"
			engine.player.getInputController<PlayerCharacter>().activeActions.jumping = false; // avoid jumping
			gameStore.dekaBotText = "Willkommen im Cinema"
			if (gameStore.dekaBotPosition != dekaBotPositions.masterclass) {
				gameStore.dekaBotPosition = dekaBotPositions.masterclass
			}
		},
		onColliderLeave: () => {
			engine.player.getInputController<PlayerCharacter>().activeActions.jumping = true; // avoid jumping
			spaceStore.hint.title = undefined
		},
		onInteract: () => {
			spaceStore.hint.title = undefined
			spaceStore.showMasterclassModal = true
			space.eventbus.emit("newAnalytics", "openMasterclass")
			space.pciSystem.triggerNearestExit()
		}
	})




}
