import { HighlightCylinder, MyLoaders, PlayerObject, PositionalHint } from "@ravespaceio/rave-engine"
import * as THREE from "three"
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader"
import { getEngine } from "~/space/engine";
import { dekaBotPositions, useGameStore } from "~/store/game";
import { setupEnergyCells } from "./energycells";
import { create3DUi } from "~/space/_lib/utils/3dui";
import { useSpaceStore } from "~/store/space";
import { SphereCollider } from "@ravespaceio/rave-engine/build/engine/src/helper/interaction/SimpleColliderSystem";
import { blockForFrontend, unblockFromFrontend } from "~/space/_lib/utils/frontend";
import { setupAlwaysPlayerFacing } from "~/space/_lib/shader/billboard";
import { Autoplay } from "swiper";
import { PlayerEntities } from "@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerObject";
import { getSpace } from "~/space/space";
import { gsap, TweenMax, TweenLite, TimelineLite, Power3, Power2, Power1, Circ, Linear } from 'gsap'
import { resetDekaBot, resetDekaBotText } from "./dekabot";
import { getProjectEnvMap } from "~/space/_lib/mechanic/textures";
import { getMatMap } from "@ravespaceio/rave-engine/build/engine/src/utils/textures";
import { PlayerCharacter } from '@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerCharacter/PlayerCharacter';
import { useToast } from "vue-toastification";
import { set } from "nuxt/dist/app/compat/capi";
import { disableFrustum } from "~/space/_lib/utils/three";

export async function setupDekaBotOld() {


	const engine = getEngine()
	const space = getSpace()
	const gameStore = useGameStore()
	const spaceStore = useSpaceStore()


	/* DEKABOT OLD */
	const dekabot_old_glb = await MyLoaders.gltfLoader.loadAsync('/dek/models/game/dekabot_old.glb') as GLTF
	const dekabot_old = dekabot_old_glb.scene
	dekabot_old.name = "DEKABOT OLD"
	engine.scene.add(dekabot_old)
	dekabot_old.scale.set(2, 2, 2)
	dekabot_old.position.set(18.9, 1.8, 8.9)
	dekabot_old.rotation.y = -5.16
	space.gui.addToSceneFolder(dekabot_old)
	disableFrustum(dekabot_old)

	//@ts-ignore
	const dekabotMat = dekabot_old.children[0].children[1].material as THREE.MeshStandardMaterial
	dekabotMat.envMap = getProjectEnvMap()
	const dekabotHappyMap = getMatMap(space.loader, "/dek/textures/dekabot_happy.jpg", { encoding: THREE.sRGBEncoding })

	// setupAlwaysPlayerFacing(dekabot_old)

	const hcOldbot = new HighlightCylinder()
	engine.scene.add(hcOldbot)
	hcOldbot.scale.set(1.4, 10, 1.4)
	space.gui.addToSceneFolder(hcOldbot)
	hcOldbot.position.set(17.4, 2, 8)
	watch(() => gameStore.questsFinished, (state) => {
		if (state > 0 && gameStore.newEnergycellFound) {
			hcOldbot.visible = true
		} else {
			if (gameStore.questStage == 3) {
				hcOldbot.visible = true
			}
		}
		if (gameStore.questStage == 3) {
			hcOldbot.visible = true
		}
	}, { immediate: true })

	/* ANIMATIONS */
	const mixer = new THREE.AnimationMixer(dekabot_old);
	const clips = dekabot_old_glb.animations;
	engine.loop.register((dt) => {
		mixer.update(dt);
	})
	const idleClip = THREE.AnimationClip.findByName(clips, "NlaTrack");
	const idle = mixer.clipAction(idleClip);
	const waveClip = THREE.AnimationClip.findByName(clips, "NlaTrack.001");
	const wave = mixer.clipAction(waveClip);
	const happyClip = THREE.AnimationClip.findByName(clips, "Happy");
	const happy = mixer.clipAction(happyClip);

	if (gameStore.questStage > 2 || gameStore.dekaBotLives) {
		wave.play();
		dekabotMat.map = dekabotHappyMap
	} else {
		idle.play();
	}


	space.pciSystem.add({
		collider: new SphereCollider(hcOldbot, 2),
		onColliderEnter: () => {
			engine.player.getInputController<PlayerCharacter>().activeActions.jumping = false; // avoid jumping
			resetDekaBotText()
			if (gameStore.dekaBotPosition != dekaBotPositions.oldBot) {
				gameStore.dekaBotPosition = dekaBotPositions.oldBot
			}

			if (gameStore.questsFinished > 0 && gameStore.newEnergycellFound) {
				spaceStore.hint.title = "Energiezellen einlegen"
			}
			if (gameStore.questStage == 3) {
				spaceStore.hint.title = "Öffnen"
			}
			if (gameStore.questStage > 0 && !gameStore.newEnergycellFound) {
				spaceStore.hint.title = "Öffnen"
			}
		},
		onColliderLeave: () => {
			engine.player.getInputController<PlayerCharacter>().activeActions.jumping = true; // avoid jumping
			spaceStore.hint.title = undefined
		},
		onInteract: () => {

			if (gameStore.questStage == 3) {
				spaceStore.showQuestModal = true
				spaceStore.hint.title = undefined
			}

			if (gameStore.canEnterRaffle) {
				spaceStore.showQuestModal = true
				spaceStore.hint.title = undefined
				return
			}

			if (gameStore.questStage > 0 && gameStore.newEnergycellFound) {
				space.eventbus.emit("enterEnergyCell")
			}
			if (gameStore.questStage > 0 && !gameStore.newEnergycellFound) {
				spaceStore.showQuestModal = true
				spaceStore.hint.title = undefined
			}
		}
	})


	space.eventbus.onAlready("enterEnergyCell", () => {
		spaceStore.hint.title = undefined


		gameStore.newEnergycellFound = false



		useToast().success("Neue Energiezelle eingelegt!")


		space.eventbus.emit("subscribeToGroup")



		if (gameStore.questsFinished == gameStore.quests.length) {
			dekabotMat.map = dekabotHappyMap
			gameStore.dekaBotLives = true
			idle.stop()
			wave.stop()
			happy.play()
			setTimeout(() => {
				happy.stop()
				wave.play()
			}, 13000);
			gameStore.questStage = 3
			hcOldbot.visible = true
			spaceStore.showQuestModal = true
		}
	})



}
