<template>
	<div class="intro" v-if="intro">
		<h1>Willkommen beim Skill Matching Game.</h1>
		<p>Dieses Spiel soll uns helfen, auf Karrieremessen aufzufallen und die Aufmerksamkeit von potenziellen Bewerber:innen auf die Deka zu lenken. Es dient als lustiger Gesprächsöffner und hilft uns, in den Dialog zu kommen, ohne den Anspruch zu haben, eine wissenschaftlich fundierte und vollständige Analyse zu liefern. Das ursprüngliche Spiel ist im Rahmen des Deka Career Day entstanden und wird nun weiterentwickelt.
			<br><br>
			Das Spiel befindet sich noch in der Testphase. Du kannst es trotzdem bereits ausprobieren. Klicke dich durch und finde heraus, in welchem Bereich der Deka unser SkillMatcher dich und deine Interessen verorten würde.
		</p>
		<a class="btn--dark" href="https://dea-t.deka.de/meta/" target="_blank">Zum SkillMatcher</a>
	</div>

	<div class="game" v-else>
		<div class="energyProgress progress-bar" v-if="!result">
			<div class="progress" :style="{ width: (currentIndex / 21 * 100) + '%' }"></div>
		</div>

		<div class="slider" v-if="currentCard">
			<div class="card" id="card">
				<img :src="'/dek/img/skillmatching/' + currentCard.image">
				<h1>{{ currentCard.skillText }}</h1>
				<p>{{ currentCard.description }}</p>
				<div class="btn--wrapper" :class="loading ? 'loading' : ''">
					<div class="chooser x" @click="choose(false)"><svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M10.2626 1.82716L24.479 16.0523L38.7042 1.82716C44.2675 -3.73616 52.7885 4.63522 47.146 10.2778L32.9296 24.4941L47.146 38.7193C52.7005 44.265 44.2499 52.7156 38.7042 47.1611L24.479 32.9447L10.2626 47.1611C4.70813 52.7156 -3.73367 44.265 1.81204 38.7193L16.0372 24.4941L1.81204 10.2778C-3.73367 4.72325 4.70813 -3.72736 10.2626 1.82716Z" fill="#EA7358" />
						</svg>
					</div>
					<div class="chooser" @click="choose(true)"><svg width="56" height="48" viewBox="0 0 56 48" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M52.1696 5.89016C45.2661 -1.82093 33.256 -1.01568 27.995 6.06058C26.5134 4.18847 23.0899 0.845098 17.3541 0.434648C6.9976 -0.160412 -2.97711 8.61739 0.827071 21.0456C1.22766 22.3269 1.84829 23.6083 2.66913 24.8796C5.04154 28.4832 11.9686 37.8228 25.172 46.7418C26.8529 47.8781 29.1369 47.8778 30.8178 46.7418C44.0213 37.8228 50.9483 28.4832 53.3207 24.8796C57.9288 17.9185 55.9609 9.68115 52.1696 5.89016Z" fill="#84DA9F" />
						</svg>
					</div>
				</div>
			</div>
			<div class="btn--small" @click="intro = false; startGame();">Neu starten</div>
		</div>

		<div class="result" v-if="result">
			<h1>Auswertung</h1>
			<template v-if="matchedAnyCategory">

				<h3>Die folgenden Bereiche der Deka oder deiner Sparkasse könnten zu dir passen</h3>

				<div class="categories" v-if="categories && result">
					<template v-for=" [key, value] of Object.entries(categories) ">
						<div class="cat" v-if="value.points >= value.neededPoints">
							<h2>{{ value.title }}</h2>
							<p v-html="value.text"></p>
						</div>
					</template>
				</div>
				<p class="dis">Hat ein Bereich dein Interesse geweckt? Schau gerne in unsere Stellenanzeige oder bewerbe dich initiativ. Links findest du unsere Stellenanzeigen sowie eine Möglichkeit mit der Personalabteilung der Deka in Kontakt zu treten, um dich ggf. initiativ zu bewerben.<br><br>Falls du noch Fragen zu den Bereichen oder generell zu den Einstiegsmöglichkeiten in der Deka hast, kannst du außerdem die Kolleginnen hier im Chat ansprechen. Wenn du persönlich mit uns sprechen möchtest, drücke "U" auf deiner Tastatur und wechsle in die Gruppe "Support". Dort warten während des Events Kolleginnen und Kollegen auf dich, um alle deine Fragen zu beantworten.<br><br>Wir wünschen dir viel Erfolg.</p>

			</template>

			<template v-else>
				<p style="text-align: center;">Leider passt kein Bereich zu deinen Angaben. Versuche es doch noch einmal und sei etwas mutiger, was deine Fähigkeiten angeht. Denke außerdem daran, nicht nur deine Fähigkeiten, sondern auch deine Interessen zu berücksichtigen.<br><br>Falls du noch Fragen zu den Bereichen oder generell zu den Einstiegsmöglichkeiten in der Deka hast, kannst du außerdem die Kolleginnen hier im Chat ansprechen. Wenn du persönlich mit uns sprechen möchtest, drücke "U" auf deiner Tastatur und wechsle in die Gruppe "Support". Dort warten während des Events Kolleginnen und Kollegen auf dich, um alle deine Fragen zu beantworten.<br><br>Wir wünschen dir viel Erfolg.<br><br></p>
			</template>


			<div class="btn--dark" @click="intro = false; startGame();">Noch einmal!</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { getSpace } from '~/space/space';
import { useSpaceStore } from '~/store/space';
import { storeToRefs } from 'pinia';
import { useGameStore } from '~/store/game';
import { useToast } from 'vue-toastification'
import { gsap, TweenMax, TweenLite, TimelineLite, Power3, Power2, Power1, Circ, Linear } from 'gsap'
import { SkillmatchingCategories, skillmatchingData } from '~/space/dek/game/skillmatchingData';

const space = getSpace()
const gameStore = useGameStore()
const spaceStore = useSpaceStore()
const toast = useToast()

const intro = ref(true)

const categories = ref({} as typeof SkillmatchingCategories)
const currentCard = ref(undefined)
const currentIndex = ref(0 as any)
const result = ref(false)
const loading = ref(false)
const matchedAnyCategory = ref(false)

function choose(state) {
	loading.value = true
	const tl = new TimelineLite()

	if (state === false) {
		tl.to("#card", 0.7, { opacity: 0, x: -40, rotate: "-6deg" });
	} else {
		tl.to("#card", 0.7, { opacity: 0, x: 40, rotate: "6deg" });
	}


	tl.add(function () {
		if (state === false) { } else {
			const values = currentCard.value.values
			const keys = Object.keys(values);
			keys.forEach((key, index) => {
				if (values[key] == true) {
					categories.value[key].points++
				}
			});

		}
		if (currentIndex.value == 20) {
			finishGame()
		} else {
			currentIndex.value++
			currentCard.value = skillmatchingData[currentIndex.value]
		}
	})
	tl.to("#card", 0.1, { x: 0, y: 20, rotate: "0deg" });
	tl.to("#card", 0.7, { opacity: 1, y: 0 });
	tl.add(function () {
		loading.value = false
	})

}

function finishGame() {
	currentCard.value = undefined
	result.value = true
	gameStore.quests[4].done = true;
	gameStore.newEnergycellFound = true
	space.eventbus.emit("newAnalytics", "energycellSkillmatching")
	for (const [key, value] of Object.entries(categories.value)) {
		if (value.points >= value.neededPoints) {
			matchedAnyCategory.value = true
		}
	}

}

function startGame() {
	const cats = JSON.parse(JSON.stringify(SkillmatchingCategories))
	categories.value = cats
	currentIndex.value = 0
	result.value = undefined
	matchedAnyCategory.value = false
	currentCard.value = skillmatchingData[0]
}

</script>

<style lang="scss" scoped>
@import "assets/css/variables";

.hint {
	@extend .flex-center;
	width: 100%;
	margin-top: 6px;

	p {
		background: #e44133;
		padding: 4px 12px;
		margin: 12px 8px !important;
		display: inline-block;
		border-radius: 6px;
		font-weight: 600;
	}
}

.energyProgress {
	height: 26px;
	border-radius: 20px;
	margin: 20px 0;
	background: linear-gradient(90deg, #191D4A -146.4%, #191D4A 125.45%);
	padding: 4px;
	width: 100%;
	max-width: 400px;

	.progress {
		position: relative;
		border: none;
		border-radius: 20px;
		background: linear-gradient(91deg, #E33272 5.39%, #E34132 109.11%);
	}
}

.intro {
	margin: 30px 0;
	@extend .flex-center;
	flex-direction: column;

	p {
		text-align: center;
		margin: 12px 0 30px;
		max-width: 700px;
	}
}

.game {
	width: 100%;
	@extend .flex-center;
	flex-direction: column;
}

.btn--small {
	font-size: 13px;
	margin-top: 40px;
	opacity: 0.5;
	cursor: pointer;
	text-align: center;
	text-decoration: underline;
}

.slider {
	margin: 30px 0 10px;
	@extend .flex-center;
	flex-direction: column;
}

.card {
	width: 400px;
	background: #fbfbfe;
	padding: 30px 20px;
	max-width: 100%;
	border-radius: 30px;
	box-shadow: 0px 10px 20px rgb(42 43 45 / 24%);
	@extend .flex-center;
	flex-direction: column;
	min-height: 500px;

	h1 {
		text-align: center;
		margin: 30px 0 7px;
		font-weight: 700;
		font-size: 22px;
	}

	p {
		text-align: center;
		font-size: 15px;
		margin-bottom: 16px;
	}

	img {
		width: 180px;
		height: auto;
		border-radius: 20px;
	}

	.chooser {
		width: 80px;
		height: 80px;
		border-radius: 200px;
		border: 6px solid #F1F1F1;
		margin: 10px;
		@extend .flex-center;
		cursor: pointer;
		transition: ease 0.2s;
		box-shadow: 0px 10px 20px rgb(42 43 45 / 0%);

		svg {
			width: 50%;
			height: auto;
			transition: ease 0.2s;

			* {
				fill: #84DA9F;
			}
		}

		&:hover {
			transform: scale(1.2) translateY(-2px);
			background: #F1F1F1;
			box-shadow: 0px 8px 12px rgb(42 43 45 / 14%);
			background: #84DA9F;
			border-color: #84DA9F;

			svg {
				* {
					fill: white;
				}
			}
		}

		&.x {


			svg {
				width: 40% !important;

				* {
					fill: #EA7358;
				}

			}

			&:hover {
				background: #EA7358;
				border-color: #EA7358;

				svg {
					* {
						fill: white;
					}
				}
			}
		}
	}

	.loading {
		pointer-events: none;
	}

	.btn--wrapper {
		@extend .flex-center;
	}
}

.result {
	@extend .flex-center;
	flex-direction: column;
	margin: 20px 0;

	h3 {
		max-width: 400px;
		text-align: center;
	}

	.categories {
		margin: 20px 0;
		width: 100%;
		@extend .flex-center;
		flex-direction: column;

		.cat {
			background: #fbfbfe;
			padding: 30px;
			border-radius: $radius;
			margin-bottom: 20px;
			width: 500px;
			max-width: calc(100% - 30px);
			border: 1px solid $lineColor;

			h2 {
				word-break: break-word;
				display: inline-block;
				border-radius: $radius;
				background: linear-gradient(91deg, #E33272 5.39%, #E34132 109.11%);
				color: white;
				padding: 5px 13px;
				font-size: 19px;
			}

		}
	}

	.dis {
		text-align: center;
		margin: 20px 0;
	}
}

@media(max-height:800px) {
	.energyProgress {
		height: 16px;
		margin: 20px 0 5px 0;
	}

	.card {
		min-height: 430px;
		padding: 20px;

		h1 {
			margin: 15px 0 7px;
		}

		img {
			width: 140px;
		}
	}

	.btn--small {
		margin-top: 20px;
	}
}
</style>
