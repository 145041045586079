import * as THREE from "three"
import Engine, { HighlightCylinder, Loader, Logging, LoggingArea, LogLevel, MeshPicker, RPMAvatar } from "@ravespaceio/rave-engine"
import { dependencies } from "../../package.json"
import LowFpsDetecter from "./_lib/utils/LowFpsDetecter"
import { useToast } from "vue-toastification"
import { setupMultiplayer } from "./_lib/mechanic/multiplayer"
import { GuiEngine } from "@ravespaceio/rave-engine/build/engine/src/helper/gui/GuiEngine"
import { GuiThreeHelper } from "@ravespaceio/rave-engine/build/engine/src/helper/gui/GuiThreeHelper"
import { getSpace } from "./space"
import { preventMediaControlls, preventBrowserGestures } from "@ravespaceio/rave-engine/build/engine/src/utils/browser"
import { useSpaceStore } from "~/store/space"
import { useMultiplayerStore } from "~/store/multiplayer"
import { setupSpace } from "./dek/main"
import Player from "@ravespaceio/rave-engine/build/engine/src/engine/player/Player"
import CloudPlayerManager from "./_lib/manager/CloudPlayerManager"
import { setupVR } from "./_lib/mechanic/vr"
import { initGui } from "./gui"
import { setupAudio } from "./dek/scene/audio"



var engine: Engine
export function getEngine(): Engine {
	if (!engine) { console.error("Engine not setup"); }
	return engine
}


export function setupEngine(canvas: HTMLCanvasElement): Engine {
	Logging.info(`setupEngine v${dependencies['@ravespaceio/rave-engine']}`, LoggingArea.Space)

	const SPACE = getSpace()
	const ENGINE = engine = new Engine({
		canvas,
		multiplayer: true,
		voicechat: true,
	})

	// hot fix to change to point click controls if pointerlock is denied
	ENGINE.inputManager.mouseInputTracker.allowPLByCanvasClick = false;

	ENGINE.inputManager.mouseInputTracker.onLeftClickEvent.on(() => {
		if (useSpaceStore().usePointJumpMode) return;
		try {
			ENGINE.config.canvas.requestPointerLock()
		} catch (e) {
			console.error(e)
			useSpaceStore().usePointJumpMode = true
		}
	})






	const mpStore = useMultiplayerStore()
	mpStore.$reset()
	mpStore.init(engine)

	if (navigator.mediaSession) {
		preventMediaControlls()
	}
	preventBrowserGestures()

	SPACE.pciSystem.setup()
	ENGINE.player.currentPlayerType = 'playerCharacter'; // define the player object to be use

	const c = document.getElementById("continue")
	if (c) ENGINE.gui.registerFirstInteractElements([c])
	ENGINE.audioManager.setup()

	// start loading after everything is initialized/setup
	const spaceStore = useSpaceStore()
	ENGINE.renderer.paused = true
	watch(() => spaceStore.onboardingDone, (state) => {
		if (!state) return;
		window.dispatchEvent(new Event('resize'));
		window.scrollTo(0, 0);
		ENGINE.renderer.paused = false
		ENGINE.renderer.resize()
		const fpsDetect = new LowFpsDetecter(ENGINE)
		fpsDetect.lowFpsEvent.on(() => { useToast().error("Low framerate detected. Change the quality in the Menu (M)."); })
	}, { immediate: true })

	if (SPACE.ENV.STAGE !== "dev") {
		window.onbeforeunload = (e) => { return "Do you want to close the page?"; };
	} else {
		window["space"] = SPACE
		window["engine"] = ENGINE
	}

	ENGINE.onDestroy.on(() => {
		engine = undefined
		SPACE.eventbinder.clearAllEvents()
	})

	SPACE.eventbus.onAlready("qualitysetting", (q) => {
		const s = q == "high" ? 1 : q == "mid" ? 0.8 : 0.5
		ENGINE.renderer.canvasScale = s
		ENGINE.renderer.resize()
	})

	setupSpace()
	initGui()

	setupAudio()

	SPACE.browser.isVR.then(isVR => { if (isVR) { setupVR(); } })
	// setupRayCastPathfinding(engine)


	SPACE.loader.addFiles([RPMAvatar.defaultMaleConfig.modelPath, RPMAvatar.defaultMaleConfig.animationsPath!, RPMAvatar.defaultFemaleConfig.modelPath, RPMAvatar.defaultFemaleConfig.animationsPath!,])
	SPACE.loader.startLoading()

	return engine
}

